/**
 * Created by stash on 12/7/16.
 */
import React from 'react';
import './MusicCatalog.css';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const styles = theme => ({
    card: {
        display: 'flex',
        margin: 10,
        backgroundColor: '#f9fbff',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 110,
        height: 100,
    },
    song_title: {
        display: 'flex',
        alignItems: 'left',
        width: 250,
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    song_desc: {
        display: 'flex',
        alignItems: 'left',
        marginLeft: 48,
        marginTop: -10,
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 15,
        width: 15,
    }
});

function SongItemCard(props) {

    const {classes} = props;

    function changePlayerSelection(e) {
        e.preventDefault();
        props.player(process.env.REACT_APP_APIURL + "/" + props.src, props.title);
    }

    return (
        <div>
            <div className={classes.controls}>
                <div className={classes.controls} onClick={changePlayerSelection}>
                    <IconButton aria-label="Play/pause">
                        <PlayArrowIcon className={classes.playIcon}/>
                    </IconButton>
                </div>
                <div className={classes.song_title}>
                    <Typography variant={"subtitle1"}>{props.title}</Typography>
                </div>
            </div>
            <div className={classes.song_desc}>
                <div className={classes.controls}>
                </div>
                <Typography variant={"caption"}>{props.description}</Typography>
            </div>
        </div>
    );
}


SongItemCard.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles, {withTheme: true})(SongItemCard);