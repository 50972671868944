import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import profilePageStyle from "../../assets/jss/material-kit-react/views/albumPage.jsx";
import DataService from 'helpers/DataService'
import GridContainer from "../../components/Grid/GridContainer";

import SongItem from "./SongItemCard";

class Album extends React.Component {

    state = {
        data: null,
        loaded: false,
        links: [],
        title: "Post"
    };

    componentDidMount() {

        let id = this.props.id;
        //Add Links
        let ds = new DataService();
        ds.fetchContent(id).then((result) => {
            let song_int = result.songs[0];

            let albumcover = null;
            if (result.hasOwnProperty("media" )) {
                albumcover = result.media[0];
            }else if (result.hasOwnProperty("images" )) {
                albumcover = result.images[0];
            }

                this.setState({
                    loaded: true,
                    data: {
                        isLoaded: true,
                        title: result.pageBody.title,
                        postdate: result.pageBody.postdate,
                        content: result.pageBody.content,
                        album_cover: albumcover,
                        songs: result.songs,
                        song: process.env.REACT_APP_APIURL + "/" +  song_int.src,
                        song_playing: song_int.title
                    }
                });
        });
    };


    render() {
        const {classes} = this.props;
        const {data} = this.state;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );


        const player = (src,name) =>{
            var dt = this.state.data;
            dt.song = src;
            dt.song_playing = name;
            this.setState({
                data: dt
            });
            document.getElementById('audioid').load();
            document.getElementById('audioid').play();
        };


        let song_list =[];
        if (this.state.loaded) {


            const title = data.title;
            const song = data.song || "";
            const content = data.content;
            let prefix = process.env.REACT_APP_APIURL || "";
            const img = prefix + "/" + data.album_cover.src;
            data.songs.forEach((x,i) => {
                song_list.push(<SongItem key={i++} src={x.src} description={x.description||""} title={x.title} cover={data.album_cover.src} player={player}/>);
            });
            const song_on = data.song_playing || "";

            return (
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.profile}>
                                    <div>
                                        <img
                                            src={img}
                                            alt="..." className={imageClasses}/>
                                    </div>
                                    <div className={classes.name}>
                                        <h3 >{title}</h3>
                                        <h4 >Playing: {song_on}</h4>
                                        <audio controls="controls" id="audioid" >
                                            Your browser does not support the <code>audio</code> element.
                                            <source src={song} />
                                        </audio>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                                {content}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                                {song_list}
                            </GridItem>
                        </GridContainer>
                        <div>
                        </div>

                    </div>
                </div>
            );
        } else {
            return <div>Loading...</div>
        }


    }
}

export default withStyles(profilePageStyle)(Album);
