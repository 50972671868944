import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.jsx";
import CatalogView  from "views/Catalog/PostPage"
import PostView  from "views/PostPage/PostPage"
import MusicList from "views/MusicPage/MusicList";
ReactDOM.render(
  <HashRouter >
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/catalog/:id" component={CatalogView} />
        <Route path="/post/:id" component={PostView} />
        <Route path="/album/:id" component={MusicList} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
