import Paper from "@material-ui/core/Paper/Paper";
import React from "react";
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import {Link} from "react-router-dom";
import {List, ListItem, ListItemText, Typography} from "@material-ui/core";

/**
 * Main Blog Post
 */

const filter = [
    {
        match: /&nbsp;/g,
        replace: " "},
    {
        match: /&#39;|&rsquo;/g,
        replace: "'"
    },
    {
        match: /&quot;|&rdquo;|&ldquo;/g,
        replace: "\""},
    {
        match: /&ndash;/g,
        replace: "-"},
    {
        match: /&gt;/g,
        replace: ">"
    },
    {
        match: /&lt;/g,
        replace: "<"
    }
];

let n = 0;


class Blog {

    /**
     *
     * @param blog_data
     * @param classes
     */
    constructor(blog_data, classes) {

        this._data = blog_data;
        this.title = this._data.pageBody.title;
        this.postDate = new Date(this._data.pageBody.postdate);
        this.formattedDate = `${this.postDate.getMonth() + 1}-${this.postDate.getDate()}-${this.postDate.getFullYear()}`;
        this.media = this._data.images || this._data.media;
        this.contentTree = this._data.pageBody.content;
        this.txtCount = 0;
        const cont = this.contentTree;
        const self = this;

        this.contentIterator = function* () {
            let h = 0;
            while (cont.length > h) {
                let treeContent = cont[h];
                h += 1;
                yield self.buildContentComponents(treeContent, classes);
            }
        }

    }

    /**
     * Check for media
     *
     * @returns {boolean}
     */
    hasMedia() {
        return this.media !== undefined && this.media !== null && this.media.length >= 1;
    }

    getPostDefaultImage(per) {
        if (this.hasMedia()) {
            return <img alt={this.media[0].name} width={"100px"}  src={process.env.REACT_APP_APIURL + "/" + this.media[0].src}/>;
        }
        return "";
    }

    getContentAsComponents() {
        let content_out = [];

        const iterator = this.contentIterator();
        for (let contentBlock of iterator) {
            content_out.push(contentBlock.content);
        }
        return content_out;
    }

    /**
     *
     * @param treeContent
     * @param classes
     * @returns {{isHeading: boolean, isSubHeading: boolean, isParagraph: boolean, isPre: boolean, isLink: boolean, isImage: boolean, isTable: boolean, hasBullets: boolean, content: Array}}
     */
    buildContentComponents(treeContent, classes) {

        let tag = Object.keys(treeContent).pop();
        let result = {
            isHeading: 'h1' === tag,
            isSubHeading: 'h2' === tag || 'h3' === tag,
            isParagraph: 'p' === tag,
            isPre: 'pre' === tag,
            isLink: 'a' === tag,
            isImage: 'img' === tag,
            isTable: 'table' === tag,
            hasBullets: 'ul' === tag,
            content: []
        };


        let html_converter = content_converter[tag];

        result.content.push(html_converter(treeContent, classes, this, tag));

        return result;
    }


    /**
     * Helper method: Find text in content tree
     *
     * @param node
     * @param tag
     * @returns {string}
     */
    static findText(node, tag) {

        let result = "";
        if (!(node instanceof Array)) return "";
        node.some(function (x) {

                let tag_check = Object.keys(x)[0];

                if (tag_check === "text" && x.hasOwnProperty('text') && tag === 'text') {
                    result = x['text'];
                    return true;
                }
                if (tag_check === tag) {
                    result = Blog.findText(x[tag], "text");
                    return true;
                } else if (tag_check !== "text") {
                    let res_test = Blog.findText(x[tag_check], tag);
                    if (res_test !== "") {
                        result = res_test;
                        return true;
                    }
                }
                return false;
            }
        );

        return result;
    }

    static findAttr(node, nodeAttr) {

        let result = "";
        if (node instanceof Array)
        {
            node.some(function (x) {
                // Expected all Arrays have Objects in them, not other raw arrays
                if (x.hasOwnProperty(nodeAttr)) {
                    result= x[nodeAttr];
                    return true;
                }
                return false;
            });
        }

        return result;
    }

    static findKeyAttr(node, nodeKey, nodeAttr) {

        let result = "";
        if (node instanceof Array)
        {
            node.some(function (x) {

                // Expected all Arrays have Objects in them, not other raw arrays
                if (x.hasOwnProperty(nodeKey)) {
                    x[nodeKey].some(g =>{
                        if (g.hasOwnProperty(nodeAttr)) {
                            result = g[nodeAttr];
                            return true;
                        }
                        return false;
                    })
                } else {
                    for (let c in x) {
                        let r = Blog.findKeyAndAttr(x[c], nodeKey, nodeAttr);
                        if (r.trim() !== "") {
                            result = r;
                            return true;
                        }
                    }
                }

                return false;
            });
        }

        return result;
    }

    static findKey(node, tag) {

        let result = "";
        if (!(node instanceof Array)) return "";
        node.some(function (x) {

                let tag_check = Object.keys(x)[0];

                if (tag_check === "text" && x.hasOwnProperty('text') && tag === 'text') {
                    result = x['text'];
                    return true;
                }
                if (tag_check === tag) {
                    result = Blog.findText(x[tag], "text");
                    return true;
                } else if (tag_check !== "text") {
                    let res_test = Blog.findText(x[tag_check], tag);
                    if (res_test !== "") {
                        result = res_test;
                        return true;
                    }
                }
                return false;
            }
        );

        return result;
    }

    countText(text) {
        return text;
    }

    static replaceEscapedChars(text) {
        return filter.reduce((r,x) => {
            return r.replace(x.match, x.replace);
        },text);
    }

    static findAndCleanText(tagRoot) {
        return Blog.replaceEscapedChars(Blog.findText(tagRoot, "text"));
    }

    static contentConverter() {
        return content_converter;
    }

}

const content_converter = {

    "ul": function (treeContent, classes, blog) {
        const result = treeContent['ul'].filter((nd) => {
            return 'li' in nd;
        }).map((v) => {
                return <Typography key={'li-' + ++n} variant={"caption"}>
                    <ListItem key={'lim-' + ++n}><ListItemText key={'lit-' + ++n} primary={blog.countText(Blog.findAndCleanText(v['li']))}/></ListItem>
                </Typography>;
            }
        );
        return <List key={'list-' + ++n}>{result}</List>;
    },
    "ol": function (treeContent, classes, blog) {
        const result = treeContent['ol'].filter((nd) => {
            return 'li' in nd;
        }).map((v,x) => {
                return <Typography key={'ol-' + ++n} variant={"caption"}>
                    <ListItem key={'lim-' + ++n}><ListItemText key={'lit-' + ++n} primary={++x + ". " + blog.countText(Blog.findAndCleanText(v['li']))}/></ListItem>
                </Typography>;
            }
        );
        return <List key={'list-' + ++n}>{result}</List>;
    },
    "table": function (treeContent, classes, blog) {

        let table = treeContent['table'].map((y) => {
            let body = (y['tbody'] || [])
                .map((row) => {
                    let r = row['tr'].map((col) => {
                        let td_html = col['td'].map((nested_col) => {
                            if (nested_col.hasOwnProperty("text")) {
                                return Blog.replaceEscapedChars(nested_col['text']);
                            } else if ((nested_col.hasOwnProperty("img") || nested_col.hasOwnProperty("b") || nested_col.hasOwnProperty("a"))) {
                                return blog.buildContentComponents(nested_col, classes).content;
                            }
                            return "";
                        });
                        return <TableCell key={'cell-' + ++n}>{td_html}</TableCell>;
                    });
                    return <TableRow key={'row-' + ++n}>{r}</TableRow>;
                });
            return <TableBody key={'tbody-' + ++n}>{body}</TableBody>;
        });

        return <Paper className={classes.table}><Table className={classes.table} key={'tbl-' + ++n}>{table}</Table></Paper>;

    },
    "img": function (treeContent,classes, blog) {
        let img =  treeContent['img'][0];
        let src = img["src"];

        if (src.indexOf("data/")>= 0) {
            src = process.env.REACT_APP_APIURL + "/"+ Blog.findAttr(treeContent['img'],"src");
        }else if (src.indexOf("id::")>= 0) {
            let imgFind = src.split("::").pop();
            let imageLib = blog.media;
            let match = imageLib.filter((c)=>{
                return c.title === imgFind;
            });

            src = process.env.REACT_APP_APIURL + "/"+ match[0].src;
        }

        let width = "100%";
        let height = "100%";
        if (img.hasOwnProperty("width")) width = img["width"];
        if (img.hasOwnProperty("height")) height = img["height"];

        return <div className={classes.paperPreCode}><img key={`img-${++n}`} src={src} height={height} width={width} alt={"for blog"}/></div>;
    },
    "p": function (treeContent, classes, blog) {

        const result = treeContent['p'].map((x) => {
            if (x.hasOwnProperty("text")) {
                return blog.countText(Blog.replaceEscapedChars(x['text']));
            } else if (!x.hasOwnProperty("p") && (x.hasOwnProperty("img") || x.hasOwnProperty("a"))) {
                return blog.buildContentComponents(x, classes).content;
            }
            return "";
        });
        return <p  key={'p-' + ++n} >{result}</p>
    },
    "a": function (treeContent) {
        return <a key={`a-${++n}`} href={Blog.findAttr(treeContent['a'],'href')}>{Blog.findAndCleanText(treeContent['a'])}</a>;
    },
    "h1": function (treeContent, classes, blog, override) {
        let text = Blog.findAndCleanText(treeContent[override]);
        if (text.trim() !== blog.title) {
            return <h2 key={'h4-' + ++n}  >{blog.countText(text)}</h2>;
        }
        return [];
    },
    "h2": function (treeContent, classes, blog,override) {
        return <h3 key={'h5-' + ++n} >{blog.countText(Blog.findAndCleanText(treeContent[override]))}</h3>;
    },
    "h3": function (treeContent, classes, blog, override) {
        return <h4 key={'h6-' + ++n} >
            {blog.countText(Blog.findAndCleanText(treeContent[override]))}
        </h4>;
    },
    "h4": function (treeContent, classes, blog, override) {
        return <h5 key={'h7-' + ++n}
                           >{blog.countText(Blog.findAndCleanText(treeContent[override]))}</h5>;
    },
    "h5": function (treeContent, classes, blog, override) {
        return <h6 key={'sub1-' + ++n}
                           >{blog.countText(Blog.findAndCleanText(treeContent[override]))}</h6>;

    },
    "h6": function (treeContent, classes, blog, override) {
        return <h6 key={'sub2-' + ++n} >
            <small>{blog.countText(Blog.findAndCleanText(treeContent[override]))}</small>
        </h6>;
    },
    "b": function (treeContent, classes, blog) {
        return <Typography key={'b-' + ++n} variant={"body1"}><strong>{Blog.findAndCleanText(treeContent['b'])}</strong></Typography>;
    },
    "pre": function (treeContent, classes, blog) {
        let text = Blog.findText(treeContent['pre'], "text");
        return <Paper key={'pre-' + ++n} className={classes.paperCode}>
            <pre className={classes.paperPreCode}>{Blog.replaceEscapedChars(blog.countText(text))}</pre>
        </Paper>;
    },
    "text": function (treeContent, classes, blog) {
        let text = Blog.replaceEscapedChars(treeContent['text']);
        return <p key={'txt-' + ++n}>{blog.countText(text)}</p>;
    },
    "continue": function(treeContent, classes, post) {
        return  <Typography key={'b-' + ++n} variant={"subtitle2"}> <Link to={`/post/${post.src.replace(".json","").replace("data/","")}`} >Continue Reading...</Link></Typography>;
    }
};

export default Blog;





