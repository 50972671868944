import ContentHelper from './ContentHelper';
const MAX_TEASER = 1000;
const h = 3;
/**
 * Catalog Index Class
 */
class CatalogHelper  {

    constructor (index) {
        this._index = index;
        this.catalogList = index.catalog;

        let valuesSorted = Object.values(this.catalogList).sort((a, b) => {
            let t = new Date(a.postdate);
            let x = new Date(b.postdate);
            return t >= x ? -1 : 1;
        });

        this.iteratorByNewest =  function* () {
            let n = 0;

            const catalog = Object.keys(this.catalogList);
            while (catalog.length > n) {
                yield EntryFactory(valuesSorted[n++]);
            }
        };
    }
}

/**
 * Internal Factory
 *
 * @param item
 * @returns {PostEntry}
 * @constructor
 */
const EntryFactory = function (item) {

    if ("albumImage" in item) {
        return new AlbumEntry(item);
    }

    return new PostEntry(item);

}

/**
 * Internal Post Entry
 *
 */
class PostEntry {

    /**
     * Build Post from JSON item
     *
     * @param item
     */
    constructor (item) {

        this.hasMedia = false;
        this.media = {};
        if ("teaserMedia" in item && item.teaserMedia.length>0) {
            this.hasMedia = true;
            this.media.src = process.env.REACT_APP_APIURL + "/" + item.teaserMedia[0].src;
            this.media.name = item.teaserMedia[0].name;
            this.media.tags = item.teaserMedia[0].tags;
            this.media.type = "image";
        }

        this.title = item.title;
        this.src = item.src;
        this.content = item.content;
        if (!(item.content instanceof Array)) {
            this.content = [{"p":[{"text":item.content}]}];
        }
        this.txtCount = 0;

        this.postDate = new Date(item.postdate);
        this.categories = item.categories;
        this.postFormattedDate = `${this.postDate.getMonth()+1}-${this.postDate.getDate()}-${this.postDate.getFullYear()}`;

    }

    getContentAsText() {

        return PostEntry.buildTextOnly(this.content);
    }

    getContentAsComponents(classes) {

        const cont = this.content;
        const self = this;
        this.contentIterator = function* () {
            let h = 0;
            while (cont.length > h  && self.txtCount < MAX_TEASER) {
                let treeContent = cont[h];
                h += 1;
                yield self.buildContentComponents(treeContent, classes);
            }
        };

        let content_out = [];

        const iterator = this.contentIterator();
        for (let contentBlock of iterator) {
            content_out.push(contentBlock.content);
        }
        if (this.txtCount >= MAX_TEASER) {
            let html_converter = ContentHelper.contentConverter();
            content_out.push(html_converter["continue"]({"continue":true},classes, this));

        }

        return content_out;
    }

    countText(text) {
        this.txtCount = this.txtCount + text.length;
        // console.log(`Count ${this.txtCount}`);
        return text;
    }

    static buildTextOnly(treeContent) {
        return treeContent.map((x) => {
            const keys = Object.keys(x);
            return keys.map((y) => {
                return ContentHelper.findText(treeContent, y);
            });

        });

    }

    buildContentComponents(treeContent, classes) {

        let tag = Object.keys(treeContent).pop();
        let result = {
            isHeading: 'h1' === tag,
            isSubHeading: 'h2' === tag || 'h3' === tag,
            isParagraph: 'p' === tag,
            isPre: 'pre' === tag,
            isLink: 'a' === tag,
            isImage: 'img' === tag,
            isTable: 'table' === tag,
            hasBullets: 'ul' === tag,
            content: []
        };
        let orig_tag = tag;
        if (tag.indexOf("h")>=0 ) {
            let n = Number(tag.substr(1,1))+h;
            tag = "h" + (n < 7 ? n : 6);

        }


        let html_converter = ContentHelper.contentConverter();

        result.content.push(html_converter[tag](treeContent, classes, this, orig_tag));

        return result;
    }
}

/**
 * Album Entry
 */
class AlbumEntry extends PostEntry {

    /**
     * Constructor
     *
     * @param item
     */
    constructor (item) {
        super(item);
        this.albumImageSrc = process.env.REACT_APP_APIURL + "/" +item.albumImage;
        this.media.type = "audio";
    }
}

export default CatalogHelper;