/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;

  let links = props.links;
  links = links.map((x,i)=>{
    return <ListItem className={classes.listItem} key={"bbb"+i}>{x}</ListItem>
  });
  return (


    <List className={classes.list}>
      {links}
      {/*<ListItem className={classes.listItem}>*/}
        {/*<Tooltip*/}
          {/*id="github"*/}
          {/*title="GitHub Repo"*/}
          {/*placement={window.innerWidth > 959 ? "top" : "left"}*/}
          {/*classes={{ tooltip: classes.tooltip }}*/}
        {/*>*/}
          {/*<Button*/}
            {/*href="https://github.com/joshua-remy"*/}
            {/*target="_blank"*/}
            {/*color="transparent"*/}
            {/*className={classes.navLink}*/}
          {/*>*/}
            {/*<i className={classes.socialIcons + " fab fa-fb"} />*/}
          {/*</Button>*/}
        {/*</Tooltip>*/}
      {/*</ListItem>*/}
      {/*<ListItem className={classes.listItem}>*/}
        {/*<Tooltip*/}
            {/*id="Bitbucket"*/}
            {/*title="GitHub Repo"*/}
            {/*placement={window.innerWidth > 959 ? "top" : "left"}*/}
            {/*classes={{ tooltip: classes.tooltip }}*/}
        {/*>*/}
          {/*<Button*/}
              {/*href="https://github.com/joshua-remy"*/}
              {/*target="_blank"*/}
              {/*className={classes.navLink}*/}
          {/*>*/}
            {/*<i className={classes.socialIcons + " fab fa-bitbucket"} />*/}
          {/*</Button>*/}
        {/*</Tooltip>*/}
      {/*</ListItem>*/}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
