import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import profilePageStyle from "assets/jss/material-kit-react/views/landingPageSections/catalogStyle.jsx";
import DataService from 'helpers/DataService'
import CatalogHelper from "../../helpers/CatalogHelper";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import defaultImage from '../../assets/img/statue1.jpg'
import Link from "react-router-dom/es/Link";
import CardHeader from "../../components/Card/CardHeader";
let b =0;
class Catalog extends React.Component {

    state = {
        catalog: null,
        loaded:false,
        links:[],
        title:"Catalog"
    };

    componentDidMount() {

        let id = this.props.id;
        //Add Links
        let ds = new DataService();
        ds.fetchCatalog(id).then((result)=>{
            let catalog = new CatalogHelper(result);
            this.setState({loaded:true,error:null,catalog:catalog,title:result.header.description});
        });
    };


    generateCard(image,title,content,posted,src) {
        const imageClasses = classNames(
            this.props.classes.imgRaised,
            this.props.classes.imgRoundedCircle,
            this.props.classes.imgFluid
        );
        return (
            <GridItem xs={12} sm={12} md={6} key={"cat"+ ++b}>
                <Card >
                    <CardHeader>
                        <GridItem xs={3} sm={3} md={3} className={this.props.classes.itemGrid}>
                            <img src={image || defaultImage} alt="..." className={imageClasses} />
                        </GridItem>
                        <h3><Link to={"/post/"+src.replace(".json","").replace("/tech/","").replace("/data/","").replace("data/","")}>{title}</Link>
                        </h3>
                        <small className={this.props.classes.smallTitle}>{posted}</small>
                    </CardHeader>
                    <CardBody>
                        {content}
                    </CardBody>
                </Card></GridItem>);
    }


  render() {
    const { classes } = this.props;
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

      if (this.state.loaded) {
          let items = [];
          let iter = this.state.catalog.iteratorByNewest();
          for (let val of iter) {
              items.push(this.generateCard(val.media.src,val.title,val.getContentAsComponents(navImageClasses),val.postFormattedDate,val.src,val.media.src));
          }
          return (
              <GridContainer>
                  {items}
              </GridContainer>
          );
      }else
      {
          return <div>Loading...</div>
      }



  }
}

export default withStyles(profilePageStyle)(Catalog);
