import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import CatalogHelper from 'helpers/CatalogHelper';
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import classNames from "classnames";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import {CloudCircle, DeviceHub, Security} from "@material-ui/icons";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import DataService from "../../../helpers/DataService";

class ProductSection extends React.Component {

  state = {
    isLoaded: false
  };

  componentDidMount() {

    let ds = new DataService();

    ds.fetchCatalog("ads").then((result)=>{
      const catalog = new CatalogHelper(result);
      this.setState({data:catalog,isLoaded:true,catalog:this.props.catalog,error:null});
    })

  };


  render() {
    const { classes } = this.props;
    let items = [];
    if (this.state.isLoaded) {

      let iter = this.state.data.iteratorByNewest();
      for (let val of iter) {
        items.push(this.generateAd(val.media.src,val.title,val.getContentAsComponents(this.props.classes)));
      }
    }


    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          {this.state.isLoaded ? items: ""}
        </GridContainer>

      </div>
    );
  }

  generateAd(image,title,content) {
    const imageClasses = classNames(
        this.props.classes.imgRaised,
        this.props.classes.imgRoundedCircle,
        this.props.classes.imgFluid
    );
    return (
        <GridItem xs={12} sm={6} md={6}>
        <Card plain>
      <GridItem xs={3} sm={3} md={3} className={this.props.classes.itemGrid}>
        <img src={image} alt="..." className={imageClasses} />
      </GridItem>
      <h4 >
        {title}
        <br />
        {/*<small className={this.props.classes.smallTitle}>Model</small>*/}
      </h4>
      <CardBody>
          {content}
      </CardBody>
        </Card></GridItem>);
  }

  getIcon(n) {
    switch (n) {
      case ('security'):
        return Security;
      case ('device_hub'):
        return DeviceHub;
      default:
        return CloudCircle;
    }

  }
}

export default withStyles(productStyle)(ProductSection);
