import axios from "axios";


class DataService {

    async fetchHeaderLinks() {

        return new Promise((res,rej)=>{
          //  if (cache.hasOwnProperty("header")) return cache.header;
            let prefix = process.env.REACT_APP_APIURL || "";
            let  uri= prefix + '/data/site-data.json?test=' + new Date().getTime();
            this.fetch(uri).then((x)=>{
             //  DataService.cache["header"]=x;
                res(x);
            }).catch((r)=>{
                rej(r);
            });

        });
    }

    async fetchContent(id) {

        return new Promise((res,rej)=>{
            //  if (cache.hasOwnProperty("header")) return cache.header;
            let prefix = process.env.REACT_APP_APIURL || "";
            let  uri= prefix + `/data/${id}.json?test=` + new Date().getTime();
            this.fetch(uri).then((x)=>{
                //  DataService.cache["header"]=x;
                res(x);
            }).catch((r)=>{
                rej(r);
            });

        });
    }

    async fetchCatalog(id) {

        return new Promise((res,rej)=>{
            //  if (cache.hasOwnProperty("header")) return cache.header;
            let prefix = process.env.REACT_APP_APIURL || "";
            let  uri= prefix + `/data/catalog/${id}.json?test=` + new Date().getTime();
            this.fetch(uri).then((x)=>{
                //  DataService.cache["header"]=x;
                res(x);
            }).catch((r)=>{
                rej(r);
            });

        });
    }

    fetch(uri) {

        return new Promise((resolve,reject)=>{
            axios.get(uri)
                .then( res => res.data)
                .then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    console.log("Error" + error);
                    reject(error);
                }
            );
        });
    }
}

export default DataService;