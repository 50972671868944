import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import profilePageStyle from "assets/jss/material-kit-react/views/albumPage.jsx";
import DataService from 'helpers/DataService'
import Button from "../../components/CustomButtons/Button";
import Album from "./Album";

class MusicList extends React.Component {

    state = {
        post: null,
        loaded:false,
        links:[]
    };

    componentDidMount() {

        //Add Links
        let ds = new DataService();
        let links =[];
        ds.fetchHeaderLinks().then((result)=>{
            for (let k in result.library.catalogData) {
                let v = result.library.catalogData[k];
                let src = v.src.replace(".json","").replace("/tech/","").replace("/data/","").replace("data/","");
                let link = "/#/catalog"+src;
                if (v.type==="page") {
                    link = "/#/post/"+src;
                }
                links.push(<Button
                    href={link}
                    color="transparent"
                    className={this.props.classes.navLink}
                >
                    {v.title}
                </Button>);
            }

            this.setState({data:result,error:null,links:links});
        });
    };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Technomason"
          rightLinks={<HeaderLinks links={this.state.links}/>}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/seashore.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
            <Album id={this.props.match.params.id} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(profilePageStyle)(MusicList);
