import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import profile from "assets/img/statue3.jpg";
import musicprofile from "assets/img/players.jpg";

import profilePageStyle from "assets/jss/material-kit-react/views/postPage.jsx";
import DataService from 'helpers/DataService'
import Catalog from "./Catalog";
import MusicCatalog from "./MusicCatalog";
import Button from "../../components/CustomButtons/Button";

class PostPage extends React.Component {

    state = {
        post: null,
        loaded: false,
        links: [],
        title: ""
    };

    componentDidMount() {

        //Add Links
        let ds = new DataService();
        let links = [];
        let title = "Catalog";
        ds.fetchHeaderLinks().then((result) => {
            for (let k in result.library.catalogData) {
                let v = result.library.catalogData[k];
                let src = v.src.replace(".json", "").replace("/tech/", "").replace("/data/", "");
                let link = "/#/catalog" + src;
                if (v.type === "page") {
                    link = "/#/post/" + src;
                }
                if (v.src.indexOf(this.props.match.params.id) >= 0) {
                    title = v.title;
                }
                links.push(<Button
                    href={link}
                    color="transparent"
                    className={this.props.classes.navLink}
                >
                    {v.title}
                </Button>);

            }

            this.setState({data: result, error: null, links: links, title: title});
        });

    };

    render() {
        const {classes, ...rest} = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );
        return (
            <div>
                <Header
                    color="transparent"
                    brand="Technomason"
                    rightLinks={<HeaderLinks links={this.state.links}/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "white"
                    }}
                    {...rest}
                />
                <Parallax small filter image={require("assets/img/hills.jpg")}/>

                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div>
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div>
                                            <img src={this.props.match.params.id !== "albums" ? profile : musicprofile} alt="..." className={imageClasses}/>
                                        </div>
                                        <div className={classes.name}>
                                            <h3 >{this.props.match.params.id !== "albums" ? "Technology Articles" : "Music Albums"}</h3>
                                        </div>
                                    </div>
                                </GridItem>
                                {this.props.match.params.id !== "albums" ?
                                    <Catalog id={this.props.match.params.id}/>
                                    :
                                    <MusicCatalog id={this.props.match.params.id}/>
                                }
                            </GridContainer>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withStyles(profilePageStyle)(PostPage);
