import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";

import profilePageStyle from "../../assets/jss/material-kit-react/views/postPage.jsx";
import DataService from 'helpers/DataService'
import GridContainer from "../../components/Grid/GridContainer";
import defaultImage from '../../assets/img/statue1.jpg'
import ContentHelper from "../../helpers/ContentHelper";

class Post extends React.Component {

    state = {
        data: null,
        loaded: false,
        links: [],
        title: "Post"
    };

    componentDidMount() {

        let id = this.props.id;
        //Add Links
        let ds = new DataService();
        ds.fetchContent(id).then((result) => {
            let content = new ContentHelper(result, this.props.classes);
            this.setState({loaded: true, error: null, data: content, title: content.title});
        });
    };


    render() {
        const {classes} = this.props;
        const {data} = this.state;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );


        if (this.state.loaded) {

            const title = data.title;
            const formattedDate = data.formattedDate;
            const content = data.getContentAsComponents();
            let prefix = process.env.REACT_APP_APIURL || "";
            const img = data.hasMedia() ? prefix + "/" + data.media[0].src : defaultImage;


            return (
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.profile}>
                                    <div>
                                        <img
                                            src={img}
                                            alt="..." className={imageClasses}/>
                                    </div>
                                    <div className={classes.name}>
                                        <h3>{title}</h3>
                                        <h4>{formattedDate}</h4>
                                    </div>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                                {content}
                            </GridItem>
                        </GridContainer>
                        <div>
                        </div>

                    </div>
                </div>
            );
        } else {
            return <div>Loading...</div>
        }


    }
}

export default withStyles(profilePageStyle)(Post);
